
import store from '@/store';
import { computed, defineComponent, ref } from 'vue';
import './FlightsRemover.scss';

import globalToast from '@/composables/common/globalToast';
import flightsRemover from '@/composables/flights/flightsRemover';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { getFlightIDsForDroneNo, deleteFlightID } = flightsRemover();

        const droneNumbersList = computed(() => store.getters.g_droneNumbers);
        const flightIDsForDroneNo = computed(() => store.getters.fl_flightidsfordroneno);

        const currentdronenumber = ref('');

         const callFetchFlightsForDroneNo = () => {
            if(currentdronenumber.value == ''){
                showGlobalToast('Select Drone Number...');
                return 0;
            }

            store.dispatch('fl_currentdroneno', currentdronenumber.value);
            getFlightIDsForDroneNo();
        }

        const callDeleteFlight = (e: any) => {
            let flightid = e.target.getAttribute('flightid');
            deleteFlightID(flightid);
        }

        return {
            droneNumbersList, currentdronenumber, flightIDsForDroneNo,
            callFetchFlightsForDroneNo, callDeleteFlight
        }
    },
})

import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const flightsRemover = () => {
    const { showGlobalToast } = globalToast();

    const getFlightIDsForDroneNo = () => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const currentdronenumber = store.getters.fl_currentdroneno;

        let requestObj = {
            type: 'flights',
            request: 'flightidsfordroneno',
            validateusername: username,
            validatepassword: password,
            currentdronenumber
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                store.dispatch('fl_flightidsfordroneno', responseObj.flightids);
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const deleteFlightID = (flightid: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'flights',
            request: 'deleteflightid',
            validateusername: username,
            validatepassword: password,
            flightid
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                showGlobalToast('Flight ID Permanently Deleted...');
                getFlightIDsForDroneNo();
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { getFlightIDsForDroneNo, deleteFlightID };
}

export default flightsRemover;